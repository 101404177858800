<section *ngIf="data && (community$ | async) as community" class="d-flex justify-content-center" [@fadeInOnEnter]>
  <a *ngIf="data.CTA_Text && data.CTA_Page_Item_Ref.Page_Url"
      class="btn px-4 {{data.CTA_Style | buttonStyle}}"
      data-analytics-cta="Find Your Home"
      [routerLink]="['../' + data.CTA_Page_Item_Ref.Page_Url]">
    <!-- Community Find Your Home Number Label -->
    <div *ngIf="data.Enable_Plan_Count && community.PlanCount"
          class="Cta__plan-count text-center rounded-circle {{planCountClass}} mr-3">
      {{community.PlanCount}}
    </div>
    <!-- Community Find Your Home Icon-->
    <cl-image *ngIf="data?.CTA_Icon"
              public-id="{{data.CTA_Icon.Src}}"
              class="mr-1"
              format="auto"
              quality="auto"
              type="fetch"
              crop="fill"
              [attr.alt]="data.CTA_Icon.Alt"
              [attr.width]="55"
              [attr.height]="55"
              [attr.aspect-ratio]="1">
    </cl-image>
    {{data.CTA_Text}}
  </a>
</section>
