<section *ngIf="data && qmis?.length" class="PlanQmiListing">
  <div class="modal-header">
    <h4 class="modal-title">{{data.Modal_Header_Text | mergeField: '{plan}': planName}}</h4>
    <span class="modal-action pr-4" (click)="closeModal()"><app-close-button></app-close-button></span>
  </div>
  <div class="PlanQmiListing__body row w-100 mx-0 px-4 overflow-y-auto justify-content-around">
    <div *ngFor="let qmi of qmis" class="col-3 my-3"
         [ngClass]="{'mb-5': qmis.length > 4}">
      <app-qmi-card [data]="qmiCardModel" [qmi]="qmi"></app-qmi-card>
    </div>
  </div>
  <div class="PlanQmiListing__footer modal-footer position-absolute row justify-content-between w-100">
    <div class="col-auto back-wrapper position-relative pl-5 cursor-pointer">
      <span class="back-wrapper-btn" (click)="closeModal()"><app-back-button></app-back-button></span>
    </div>
    <button *ngIf="data.View_All_Button_Page_Item_Ref"
      class="btn m-3 {{data.View_All_Button_Style | buttonStyle}}"
      (click)="closeModal()"
      [routerLink]="[trimmedUrl + data.View_All_Button_Page_Item_Ref.Page_Url]"
      [queryParams]="{seriesID: 'All', homeType: 'Qmis'}">
      {{data.View_All_Button_Text}}
    </button>
  </div>
</section>
