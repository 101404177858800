import { Component, OnInit, Input } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil, concatMap } from 'rxjs/operators';
import { Plan } from 'src/app/models/product/plan.model';
import { forkJoin } from 'rxjs';
import { PlanSummaryModel } from 'src/app/models/content/plan/plan-summary.model';
import { Location } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { HersScoreModalComponent } from '../../shared/modals/hers-score-modal/hers-score-modal.component';
import { HersScoreService } from 'src/app/core-services/hers-score.service';
import { HersScoreModel } from 'src/app/models/hers-score-configuration.model';
import { BaseComponent } from '../../shared/base/base.component';
import { LmpService } from 'src/app/core-services/lmp.service';
import { LmpConfigurationModel } from 'src/app/models/lmp-configuration.model';
import { PlanBrochureService } from 'src/app/core-services/plan-brochure.service';
import { Community } from 'src/app/models/product/community.model';
import { fadeInOnEnterAnimation, fadeInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-plan-summary',
  templateUrl: './plan-summary.component.html',
  styleUrls: ['./plan-summary.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation(),
    fadeInOnEnterAnimation({ duration: 600, delay: 1000 })
  ]
})
export class PlanSummaryComponent extends BaseComponent implements OnInit {

  @Input() data: PlanSummaryModel;

  plan: Plan;
  community: Community;
  HersScore: HersScoreModel;
  lmpConfiguration: LmpConfigurationModel;

  constructor(private communityService: CommunityService,
              private modalService: NgbModal,
              private route: ActivatedRoute,
              private location: Location,
              private hersScoreService: HersScoreService,
              private lmpService: LmpService) { super(); }

  ngOnInit() {
    this.route.params.pipe(
      map(routeParam => routeParam.id),
      concatMap((planId: string) => {
        const observables = [ this.communityService.getPlanInfoById(planId),
                              this.hersScoreService.HersScoreitem,
                              this.lmpService.LmpConfiguration,
                              this.communityService.community
                              ];
        return forkJoin(observables);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(([plan, hers, lmp, community]: any[]) => {
      if (plan) {
        this.plan = plan;
      }

      if (hers) {
        this.HersScore = hers;
      }

      if (lmp) {
        this.lmpConfiguration = lmp;
      }

      if (community) {
        this.community = community;
      }

    });
  }

  openHersModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(HersScoreModalComponent,
      { windowClass: `${ModalClasses.Full} ${ModalClasses.Summary}`, backdrop: false });
    modalRef.componentInstance.HersScore = this.HersScore;
    modalRef.componentInstance.plan = this.plan;
  }

  backButton() {
    // Close any active modals on the screen and if there are none, go back.
    this.modalService.hasOpenModals() ? this.modalService.dismissAll() : this.location.back();
  }
}
