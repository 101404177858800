import { CTAPageItemRef } from '../../cta-page-item-ref.model';
import { HersScoreModel } from '../../hers-score-configuration.model';
import { LmpConfigurationModel } from '../../lmp-configuration.model';
import { KioskImage } from '../media/kiosk-image.model';

export class PlanCardModel {
  Series_Name: string;
  Price: string;
  Bedrooms: string;
  Bathrooms: string;
  Garage: string;
  Square_Feet: string;
  CTA_Page_Item_Ref: CTAPageItemRef;
  CTA_Detail: string;
  LMP_Starting_From: string;
  QMI_Availability: string;
  QMI_Only: string;
  Almost_Sold_Out: string;
  Future_Release: string;
  Last_Chance: string;
  Last_Home_Design: string;
  Model_For_Sale: string;
  Model_Open: string;
  New_Model: string;
  New_Plan: string;
  Sold_Out: string;
  HersScore: HersScoreModel;
  LmpConfiguration: LmpConfigurationModel;
  Virtual_Tour_CTA_Text: string;
  Virtual_Tour_CTA_Icon: KioskImage;
  Virtual_Tour_CTA_Style: string;
  Virtual_Tour_Modal_Header_Text: string;
  Video_Tour_CTA_Text: string;
  Video_Tour_CTA_Icon: KioskImage;
  Video_Tour_CTA_Style: string;
  Video_Tour_Modal_Header_Text: string;
  Video_Tour_Modal_Caption_Placement: string;
  See_Lots_CTA_Detail: string;
  View_Home_CTA_Detail: string;
  Homesites_Label: string;
  Quick_Move_Ins_Label: string;
  Call_For_Pricing_Text: string;
}
