<div *ngIf="plan" class="PlanSummary text-center py-3 fixed-bottom d-flex justify-content-center align-items-center" [@fadeInUpOnEnter]>
  <div  [ngClass]="plan?.PlanName.length > 14 ? 'col-auto' : 'col-2'">
    <div class="PlanSummary__planName">{{plan.PlanName}}</div>
    <div *ngIf="plan.SeriesName" class="PlanSummary__label">{{data.Series_Name | mergeField: '{series}':plan.SeriesName}}</div>
  </div>
  <div class="row align-items-center">
    <div class="col-auto mx-4"
        [ngClass]="{'col-2' : plan.CallForPricingFlag}">
      <div *ngIf="!plan.CallForPricingFlag">
        <div class="PlanSummary__value">{{plan.Price | currency: 'USD':'symbol':'1.0-0'}}</div>
        <div class="PlanSummary__label">{{data.Price}}</div>
      </div>
      <div *ngIf="plan.CallForPricingFlag" class="PlanSummary__label">
        <h6>{{data.Call_For_Pricing_Text}}</h6>
      </div>
    </div>
    <div class="col-auto mx-4">
      <div class="PlanSummary__value">{{plan.Bedrooms | range : plan.MaxBedrooms}}</div>
      <div class="PlanSummary__label">{{data.Bedrooms}}</div>
    </div>
    <div class="col-auto mx-4">
      <div class="PlanSummary__value">{{plan.MinTotalBaths | range : plan.MaxTotalBaths}}</div>
      <div class="PlanSummary__label">{{data.Bathrooms}}</div>
    </div>
    <div class="col-auto mx-4">
      <div class="PlanSummary__value">{{plan.SquareFeet}}</div>
      <div class="PlanSummary__label">{{data.Square_Feet}}</div>
    </div>
    <div class="col-auto mx-4">
      <div class="PlanSummary__value">{{plan.Garages | range : plan.MaxGarages}}</div>
      <div class="PlanSummary__label">{{data.Garage}}</div>
    </div>
    <div class="col-auto mx-4" *ngIf="plan?.HERSScore && HersScore?.Hers_Score_CTA_ToolTip && HersScore?.Hers_Score_CTA_Label">
      <div class="PlanSummary__value d-inline-flex align-items-center">
        {{plan.HERSScore}}
        <button class="ml-1 btn-i btn-circle btn-sm" title="{{HersScore.Hers_Score_CTA_ToolTip}}" (click)='openHersModal()'>i</button>
      </div>
      <div class="PlanSummary__label">{{HersScore.Hers_Score_CTA_Label}}</div>
    </div>
    <div *ngIf="plan.MortgagePayment?.TotalMonthlyPayment && lmpConfiguration?.Enable_LMP && !plan.CallForPricingFlag" class="col-auto mx-4">
      <div class="PlanSummary__value d-inline-flex align-items-center">
        {{plan.MortgagePayment.TotalMonthlyPayment | currency: 'USD':'symbol':'1.0-0'}} /mo
        <app-lmp-tooltip [data]="lmpConfiguration" [mortgagePayment]="plan.MortgagePayment"></app-lmp-tooltip>
      </div>
      <div class="PlanSummary__label">{{data.LMP_Starting_From}}</div>
    </div>
    <div class="col-auto back-wrapper pl-3">
      <span class="back-wrapper-btn" (click)='backButton()'><app-back-button></app-back-button></span>
    </div>
  </div>
  <app-request-brochure-cta [ctaData]="data"
    [currentPlanData]="plan"
    [@fadeInOnEnter]>
  </app-request-brochure-cta>
</div>

