<div *ngIf="plan && data"
      class="HorizontalPlanCard card__container d-flex mt-1 mb-5"
      [ngClass]="large ? 'HorizontalPlanCard--large mx-1' : 'mx-4'">
  <div class="px-0 d-flex flex-column"
       [ngClass]="large ? 'col-7' : 'col-6'">
    <div *ngIf="plan.Images?.length && plan.Images[0]?.Path">
      <div *ngIf="statusOverlayText?.length > 0"
            class="image__status-overlay position-absolute text-light bg-dark p-1">
        <div *ngFor="let overlayLabel of statusOverlayText">
          {{ overlayLabel }}
        </div>
      </div>
      <cl-image
        class="rounded w-100 h-100"
        public-id="{{ plan.Images[0].Path }}"
        format="auto"
        quality="auto"
        width="auto"
        type="fetch"
        crop="fill"
        [attr.alt]="plan.Images[0].AltText ? plan.Images[0].AltText : ''"
        [attr.aspect-ratio]="1.5">
      </cl-image>
    </div>
    <div class="d-flex flex-row justify-content-center align-items-center mt-auto py-3">
      <button *ngIf="data.See_Lots_CTA_Detail && plan.Id !== selectedPlanId"
              class="btn btn-primary HorizontalPlanCard__button mx-1"
              data-analytics-cta="See Lots"
              (click)="setSelectedPlanId(plan.Id)">
        {{ data.See_Lots_CTA_Detail }}
      </button>
      <div *ngIf="plan.Id === selectedPlanId" class="flex-column mr-4">
        <div *ngIf="data.Homesites_Label && numberOfLotsAvailableForSelectedPlan" class="d-flex flex-row align-items-center">
          <div class="HorizontalPlanCard__dot HorizontalPlanCard__dot--homesites mx-1"></div>
          <div class="px-1">{{numberOfLotsAvailableForSelectedPlan}}</div>
          <div class="HorizontalPlanCard__indicator-text">{{data.Homesites_Label}}</div>
        </div>
        <div *ngIf="data.Quick_Move_Ins_Label && plan.InventoryCount" class="d-flex flex-row align-items-center">
          <div class="HorizontalPlanCard__dot HorizontalPlanCard__dot--qmi mx-1"></div>
          <div class="px-1">{{plan.InventoryCount}}</div>
          <div class="HorizontalPlanCard__indicator-text">{{data.Quick_Move_Ins_Label}}</div>
        </div>
      </div>
      <button *ngIf="data.CTA_Page_Item_Ref?.Page_Url && data.View_Home_CTA_Detail"
              class="btn HorizontalPlanCard__button mx-1"
              [ngClass]="plan.Id !== selectedPlanId ? 'btn-default' : 'btn-primary px-4'"
              data-analytics-cta="View Home"
              [routerLink]="[router?.url?.split('/page/')[0] + '/page/' + data.CTA_Page_Item_Ref.Page_Url + '/' + plan.Id]"
              (click)="handlePlanClicked(plan)">
        {{ data.View_Home_CTA_Detail }}
      </button>
    </div>
  </div>
  <div class="pl-4 pb-3 d-inline-flex flex-column justify-content-between"
       [ngClass]="large ? 'col-5' : 'col-6'">
    <div class="my-3">
      <div class="HorizontalPlanCard__plan-name">{{ plan.PlanName }}</div>
      <div *ngIf="plan.SeriesName" class="HorizontalPlanCard__data-label font-italic">
        {{ data.Series_Name | mergeField: "{series}":plan.SeriesName }}
      </div>
    </div>
    <div *ngIf="plan.Price" class="my-3">
      <div class="HorizontalPlanCard__plan-data">
        {{ plan.Price | currency: "USD":"symbol":"1.0-0" }}
      </div>
      <div class="HorizontalPlanCard__data-label font-italic">
        {{ data.Price }}
      </div>
    </div>
    <div class="row my-3">
      <div *ngIf="plan.Bedrooms" class="col-6">
        <div class="HorizontalPlanCard__plan-data">
          {{ plan.Bedrooms | range: plan.MaxBedrooms }}
        </div>
        <div class="HorizontalPlanCard__data-label">{{ data.Bedrooms }}</div>
      </div>
      <div *ngIf="plan.MinTotalBaths" class="col-6">
        <div class="HorizontalPlanCard__plan-data">
          {{ plan.MinTotalBaths | range: plan.MaxTotalBaths }}
        </div>
        <div class="HorizontalPlanCard__data-label">{{ data.Bathrooms }}</div>
      </div>
    </div>
    <div class="row my-3">
      <div *ngIf="plan.Garages" class="col-6">
        <div class="HorizontalPlanCard__plan-data">
          {{ plan.Garages | range: plan.MaxGarages }}
        </div>
        <div class="HorizontalPlanCard__data-label">{{ data.Garage }}</div>
      </div>
      <div *ngIf="plan.SquareFeet" class="col-6">
        <div class="HorizontalPlanCard__plan-data">{{ plan.SquareFeet }}</div>
        <div class="HorizontalPlanCard__data-label">{{ data.Square_Feet }}</div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-6" *ngIf="hasHersScore">
        <div class="HorizontalPlanCard__plan-data d-inline-flex align-items-center">
          {{ plan.HERSScore }}
          <button class="ml-1 btn-i btn-circle btn-sm"
                  title="{{ data.HersScore.Hers_Score_CTA_ToolTip }}"
                  (click)="openHersModal()">
            i
          </button>
        </div>
        <div class="HorizontalPlanCard__data-label">
          {{ data.HersScore.Hers_Score_CTA_Label }}
        </div>
      </div>
      <div *ngIf="plan.MortgagePayment?.TotalMonthlyPayment && data.LmpConfiguration?.Enable_LMP" class="col-6">
        <div class="HorizontalPlanCard__plan-data d-inline-flex align-items-center">
          {{ plan.MortgagePayment.TotalMonthlyPayment | currency: "USD":"symbol":"1.0-0" }}
          /mo
          <app-lmp-tooltip [data]="data.LmpConfiguration" [mortgagePayment]="plan.MortgagePayment"></app-lmp-tooltip>
        </div>
        <div class="HorizontalPlanCard__data-label">
          {{ data.LMP_Starting_From }}
        </div>
      </div>
    </div>
  </div>
</div>
