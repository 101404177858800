<section *ngIf="data" class="BoxCard d-flex justify-content-center p-4">
  <div class="card__container--box d-flex rounded-lg" [ngStyle]="{'background': data.Background_Color}">
    <div *ngIf="data.Image">
      <cl-image
        class="w-100 rounded-top"
        public-id="{{data.Image.Src}}"
        format="auto"
        quality="auto"
        type="fetch"
        crop="fill"
        [attr.alt]="data.Image.Alt"
        [attr.aspect-ratio]="1.5"></cl-image>
    </div>
    <div class="py-3 px-4 m-1">
      <h2 class="card__header--box d-flex {{data.Header_Alignment | alignmentClass}}">
        {{data.Header}}
      </h2>
      <div class="d-flex flex-column {{data.Description_Alignment | alignmentClass}}" [innerHTML]="data.Description"></div>
      <div class="d-flex  {{data.CTA_Alignment | alignmentClass}}">
        <div *ngIf="data?.CTA_Page_Item_Ref?.Page_Url && data?.CTA_Text" class="pt-3 d-flex">
          <a class="btn {{data.CTA_Style | buttonStyle}}"
            [routerLink]="['../' + data.CTA_Page_Item_Ref.Page_Url]">
            {{data.CTA_Text}}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
