<div *ngIf="data && (plan$ | async) as plan"
      class="planCtas"
      [@fadeInOnEnter]>
  <button *ngIf="plan.InventoryCount && data.QMI_CTA_Text"
           class="btn {{data.QMI_CTA_Style | buttonStyle}} mr-0"
           data-analytics-cta="Quick Move-Ins"
           (click)="openQmiListModal(plan)">
    <div class="planCtas__qmiCount {{qmiCountClass}} mr-2">
      {{plan.InventoryCount}}
    </div>
    {{data.QMI_CTA_Text}}
  </button>
  <br>
  <a *ngIf="data?.Homesite_CTA_Text && data.Homesite_CTA_Page_Item_Ref?.Page_Url"
      class="btn {{data.Homesite_CTA_Style | buttonStyle}}"
      data-analytics-cta="Find a Homesite"
      [routerLink]="['../../' + data.Homesite_CTA_Page_Item_Ref.Page_Url]"
      [queryParams]="{planId:plan.Id}">
    {{data.Homesite_CTA_Text}}
  </a>
</div>
