import { CTAPageItemRef } from '../../cta-page-item-ref.model';
import { AlphaVisionLotFacing, AlphaVisionLotType } from '../../maps/alphavision-map-data.model';

export class LotMapLegendModel {
  Available: string;
  Quick_Move_In: string;
  Model_Available: string;
  Sold: string;
  Not_Released: string;
  Homesite: string;
  Homesites_Count: string;
  Quick_Move_In_Count: string;
  Home_Design_Count: string;
  Home_Status_Filter: string;
  Lot_Direction_Filter: string;
  Lot_Type_Filter: string;
  Lot_Status: string;
  Lot_Facing: string;
  Lot_Type: string;
  Lot_Premium: string;
  Lot_Address: string;
  Plan_Elevation: string;
  Estimated_Move_In_Date: string;
  Homesite_Availability: string;
  Unique_Lot: string;
}

export class LotMapLegendFormModel {
  //Status Properties
  availableValue: any = { value: true };
  modelAvailableValue: any = { value: true };
  qmiAvailableValue: any = { value: true };
  soldValue: any = { value: true };
  notReleasedValue: any = { value: true };

  facingProperties: any[] = [{value: true}];
  typeProperties: any[] = [{value: true}];

  blackListedFacingValues: string[] = [AlphaVisionLotFacing.NA];
  blackListedTypeValues: string[] = [AlphaVisionLotType.None];
}

export class LegendItem {
  label: string;
  name: string;
  className: string;
  checked: any = { value: true };

  constructor(label, value, className?) {
    this.label = label;
    this.name = value;
    this.className = className;
    this.checked = value;
  }
}

export class CommunityLotMapModel extends LotMapLegendModel {
  Series_Label: string;
  Price: string;
  Bedrooms: string;
  Bathrooms: string;
  Garage: string;
  Square_Feet: string;
  LMP_Starting_From: string;
  See_Lots_CTA_Text: string;
  View_Home_CTA_Text: string;
  CTA_Page_Item_Ref: CTAPageItemRef;
  Homesites_Label: string;
  Quick_Move_Ins_Label: string;
  Plan_Almost_Sold_Out: string;
  Plan_Future_Release: string;
  Plan_Last_Chance: string;
  Plan_Last_Home_Design: string;
  Plan_Model_For_Sale: string;
  Plan_Model_Open: string;
  Plan_New_Model: string;
  Plan_New_Plan: string;
  Plan_Sold_Out: string;
  Plan_QMI_Only: string;
  Filter_Close_Matches: string;
  Homesite_Selected_Lot_Label: string;
  Homesites_Map_Error_Label: string;
}