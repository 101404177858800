import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { NgbSlideEvent, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { AdobeLaunchCtaAttribute } from 'src/app/models/analytics/adobe-launch-attribute-enum';
import { CarouselOptionsModel } from 'src/app/models/carousel-options.model';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, AfterViewInit {

  @Input() carouselOptions: CarouselOptionsModel;

  @Output() slideClickEvent: EventEmitter<number> = new EventEmitter();
  @Output() activeSlideChange: EventEmitter<number> = new EventEmitter();

  @ViewChild(NgbCarousel) ngbCarousel: NgbCarousel;

  activeSlideId: number;
  imagesLoading: boolean = true;

  constructor(private elemRef: ElementRef, private adobeLaunchService: AdobeLaunchService) { }

  ngOnInit() {
    if (this.carouselOptions) {
      this.activeSlideId = parseInt(this.carouselOptions.InitialSlideId, 10);
    }
  }

  ngAfterViewInit() {
    // Analytics CTA Attributes for Bootstrap Carousel Prev/Next Arrow elements
    const leftScroll = this.elemRef.nativeElement.querySelectorAll('.carousel-control-prev')[0];
    if (leftScroll) {
      this.adobeLaunchService.setAnalyticsCtaAttributeOnElem(leftScroll, AdobeLaunchCtaAttribute.PhotoScroll);
    }

    const rightScroll = this.elemRef.nativeElement.querySelectorAll('.carousel-control-next')[0];
    if (rightScroll) {
      this.adobeLaunchService.setAnalyticsCtaAttributeOnElem(rightScroll, AdobeLaunchCtaAttribute.PhotoScroll);
    }
  }

  onSlideClick(slideId: number): void {
    this.slideClickEvent.next(slideId);
  }

  onSlideChange(slideEvent: NgbSlideEvent): void {
    this.activeSlideId = parseInt(slideEvent.current, 10);
    this.activeSlideChange.next(this.activeSlideId);
  }

  selectSlide(slideId: number): void {
    this.ngbCarousel.select(slideId.toString());
  }

  imageLoaded(index?: number) {
    if ((this.carouselOptions && this.carouselOptions.HasSingleImage) || (index === 0)) {
      this.imagesLoading = false;
    }
  }

  imageError(index?: number) {
    if ((this.carouselOptions && !this.carouselOptions.HasSingleImage) || (index === 0)) {
      this.carouselOptions.Images = this.carouselOptions.Images.splice(index, 1);
      this.imagesLoading = false;
    }
  }
}
