<section *ngIf="plan" class="card__container">
  <div *ngIf="plan?.Images[0]">
    <div *ngIf="statusOverlayText?.length > 0" class="image__status-overlay position-absolute text-light bg-dark p-1">
      <div *ngFor="let overlayLabel of statusOverlayText;">
        {{overlayLabel}}
      </div>
    </div>
    <cl-image
      class="w-100"
      public-id="{{plan.Images[0].Path}}"
      format="auto"
      quality="auto"
      width="auto"
      type="fetch"
      crop="fill"
      [attr.alt]="plan.Images[0].AltText"
      [attr.aspect-ratio]="1.5"></cl-image>
  </div>
  <h1 class="card__header">{{plan.PlanName}}</h1>
  <div class="container">
    <div class="col-12 card__grid-item-series" *ngIf="plan.SeriesName">
      {{data.Series_Name | mergeField: '{series}':plan.SeriesName}}
    </div>
    <div class="col-12 card__grid-item"
         [ngClass]="{'d-flex justify-content-center align-items-center pb-0' : plan.CallForPricingFlag}">
         <div *ngIf="!plan.CallForPricingFlag">
          <div class="card__grid-item-value-price">{{plan.Price | currency: 'USD':'symbol':'1.0-0'}}<br></div>
          <div class="card__grid-item-text-price">{{data.Price}}</div>
        </div>
        <div class="card__grid-item-text-price" *ngIf="plan.CallForPricingFlag">
          <h2 class=" font-weight-lighter">{{data.Call_For_Pricing_Text}}</h2>
        </div>
    </div>
    <div class="row my-2">
      <div class="col-4 card__grid-item">
        <div class="card__grid-item-value">{{plan.Bedrooms | range : plan.MaxBedrooms}}<br></div>
        <div class="card__grid-item-text">{{data.Bedrooms}}</div>
      </div>
      <div class="col-4 card__grid-item">
        <div class="card__grid-item-value">{{plan.MinTotalBaths | range : plan.MaxTotalBaths}}<br></div>
        <div class="card__grid-item-text">{{data.Bathrooms}}</div>
      </div>
      <div class="col-4 card__grid-item" *ngIf="plan.Garages">
        <div class="card__grid-item-value">{{plan.Garages | range : plan.MaxGarages}}<br></div>
        <div class="card__grid-item-text">{{data.Garage}}</div>
      </div>
    </div>
    <div class="row my-2 justify-content-center">
      <div class="col-4 card__grid-item">
          <div class="card__grid-item-value">{{plan.SquareFeet}}<br></div>
          <div class="card__grid-item-text">{{data.Square_Feet}}</div>
      </div>
      <div class="col-4 card__grid-item" *ngIf="plan?.HERSScore && data?.HersScore && data.HersScore?.Hers_Score_CTA_ToolTip && data.HersScore?.Hers_Score_CTA_Label">
          <div class="card__grid-item-value d-inline-flex align-items-center">
            {{plan.HERSScore}}
            <button class="ml-1 btn-i btn-circle btn-sm" title="{{data.HersScore.Hers_Score_CTA_ToolTip}}" (click)='openHersModal()'>i</button>
            <br>
          </div>
          <div class="card__grid-item-text">{{data.HersScore.Hers_Score_CTA_Label}}</div>
      </div>
      <div *ngIf="plan.MortgagePayment?.TotalMonthlyPayment && data.LmpConfiguration?.Enable_LMP && !plan.CallForPricingFlag" class="col-4 pl-0 card__grid-item">
          <div class="card__grid-item-value-lmp d-inline-flex align-items-center">
            {{plan.MortgagePayment.TotalMonthlyPayment | currency: 'USD':'symbol':'1.0-0'}} /mo
            <app-lmp-tooltip [data]="data.LmpConfiguration" [mortgagePayment]="plan.MortgagePayment"></app-lmp-tooltip>
            <br>
          </div>
          <div class="card__grid-item-text">{{data.LMP_Starting_From}}</div>
      </div>
    </div>
    <div *ngIf="isTourCard" class="row my-2 justify-content-center">
      <button *ngIf="plan.VirtualTour" (click)="openVirtualTourModal()"
               data-analytics-cta="Virtual Tour"
               class="btn d-inline-flex justify-content-center align-items-center px-4 mx-3 {{data.Virtual_Tour_CTA_Style | buttonStyle}}">
        <cl-image *ngIf="data.Virtual_Tour_CTA_Icon"
          class="mr-1"
          public-id="{{data.Virtual_Tour_CTA_Icon.Src}}"
          format="auto"
          quality="auto"
          type="fetch"
          crop="fill"
          height="50"
          width="50"
          [attr.aspect-ratio]="1">
        </cl-image>
        {{data.Virtual_Tour_CTA_Text}}
      </button>
      <button *ngIf="hasVideos" (click)="openVideoTourModal()"
               data-analytics-cta="Video Tour"
               class="btn d-inline-flex justify-content-center align-items-center px-4 mx-3 {{data.Video_Tour_CTA_Style | buttonStyle}}">
        <cl-image *ngIf="data.Video_Tour_CTA_Icon"
          class="mr-1"
          public-id="{{data.Video_Tour_CTA_Icon.Src}}"
          format="auto"
          quality="auto"
          type="fetch"
          crop="fill"
          height="50"
          width="50"
          [attr.aspect-ratio]="1">
        </cl-image>
        {{data.Video_Tour_CTA_Text}}
      </button>
    </div>
    <div class="card__button-container">
      <a *ngIf="data?.CTA_Page_Item_Ref"
        [ngClass]="isTourCard ? 'PlanCard__view-home-link' : 'btn-card btn btn-primary'"
        data-analytics-cta="View Home"
        [routerLink]="['../' + data.CTA_Page_Item_Ref.Page_Url + '/' + plan.Id]"
        (click)="handlePlanClicked(plan)">
        {{data.CTA_Detail}}
      </a>
    </div>
  </div>
</section>
